// import { Link } from "react-router-dom";
// import reportPDF from "./assets/report.pdf";
import { Link } from "react-router-dom";
import headerImage from "./assets/header-image.jpeg";

function App() {
  return (
    <div className="max-w-lg m-auto px-4 py-4 min-h-screen">
      <header>
        <img className="w-100 p-1 max-w-full rounded m-auto" src={headerImage} alt="Join Fraternity" />
      </header>
      {/* 
      <div className="divider"></div>
      <div className="m-auto text-center">
        <button className="btn btn-outline m-1 w-5/12">English</button>
        <button className="btn btn-outline m-1 w-5/12">Hindi</button>
        <button className="btn btn-outline m-1 w-5/12">Tamil</button>
        <button className="btn btn-outline m-1 w-5/12">Kannada</button>
      </div>
      */}
      <div className="divider">Fraternity Movement</div>

      <div className="p-2 pb-32 text-neutral">
        <div>
          <p>
            <span className="text-lg">Why Fraternity ?</span>
            <br /><br />
            In India today, the promise of democracy is one that remains hollow from inside, even more so than ever before. If we have had formal elections, it is assumed to be a democratic country. If we have a judiciary, we assume that there exists a rule of law. But the broader promise of justice, and more specifically the ethical, moral and substantive question of social justice is missing.
            <br /><br />
            Where is social justice for the marginalized sections of this country ? Too often there is talk of a ‘majority’ and a ‘minority’ – but it is the vast majority – of Muslims, Dalits, Adivasis, OBCs and other religious minorities, who are being ruled over by a small and powerful minority in every sense of the word, at every level of government and everyday life. Whether it is the question of lynchings, the introduction of CAA/NRC, the countless amendments to UAPA and NIA Acts, the forced disappearance of Najeeb Ahmed and institutional murder of Rohith Vemula, the hijab ban, the selective bulldozing, the continued institutional discrimination against the marginalized, the continued targeting of dissenting students and youth, numerous experiences of gender and regional discrimination – it paints a very bleak picture, one which seems impossible to escape from.
            <br /><br />
            The electoral developments have only sharpened this alienation and marginalization. But they cannot, and have not put an end to the idea of association, and the promise of social justice beyond these indignities.
            <br /><br />
            Who represents you? This is a question for all of us to answer.
            <br /><br />
            Fraternity Movement is a students' - youth organization that strives to realise a society based on inclusive democracy which respects difference, equality that enables to treat people equally and equitably, sense of justice which eradicates human right violations and preserves mutual fraternity in its every aspect.
            <br /><br />
            <span className="text-lg">Welcome !</span>
          </p>
        </div>
        <div className="py-8 text-center fixed bottom-0 left-0 right-0
          bg-gradient-to-b from-transparent to-white">
          <Link to="/register" className="btn btn-lg bg-sky-600 border-none text-white btn-wide shadow-lg">Join Fraternity</Link>
        </div>
      </div>
    </div>
  );
}

export default App;
