
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAH7y8ajRXttB_o8syNDszv4Zlorcp8_s8",
  authDomain: "fraternityindia-60a8e.firebaseapp.com",
  projectId: "fraternityindia-60a8e",
  storageBucket: "fraternityindia-60a8e.appspot.com",
  messagingSenderId: "34069811254",
  appId: "1:34069811254:web:9a312a82beeec441884897"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
  
export default db;